import React from "react"

import "../styles/main.scss"
// import "../../node_modules/jquery/dist/jquery.min.js"
// import "../../node_modules/@popperjs/core/dist/umd/popper.js"
// import "../../node_modules/bootstrap/dist/js/bootstrap.min.js"

import Footer from "./footer"
import Header from "./header"
import { withPrefix } from "gatsby"
import { Helmet } from "react-helmet"
import CookieConsent from "react-cookie-consent"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

// if (typeof window !== `undefined`) {
//   require(jquery)
// }

// typeof window !== `undefined`
//   ? require("../../node_modules/jquery/dist/jquery.min.js")
//   : null

const Layout = props => {
  return (
    <div className="layout">
      <Header />
      {props.children}
      <CookieConsent
        location="bottom"
        buttonText="Elfogad"
        declineButtonText="Elutasít"
        cookieName="gatsby-gdpr-google-analytics"
        style={{
          background: "#4c4340",
          color: "#bcb4b3",
          paddingLeft: "10%",
          paddingRight: "10%",
          fontSize: "15px",
          textAlign: "center",
        }}
        buttonStyle={{
          background: "#b9252b",
          borderStyle: "solid",
          borderColor: "#a62126",
          borderRadius: "3px",
          borderWidth: "2px",
          color: "#bcb4b3",
          fontSize: "16px",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <span className="">
          Oldalam (a többi weboldalhoz hasonlóan) sütiket használ a jobb
          felhasználói élmény biztosításához.
          <a
            href={`adatkezelesi-tajekoztato-lassucsardas.pdf`}
            target="_blank"
            rel="noreferrer"
            className="small"
          >
            (Bővebben)
          </a>
        </span>
      </CookieConsent>
      <Footer />
      <Helmet defer={false}>
        <script src={withPrefix("jquery.min.js")} type="text/javascript" />
        <script src={withPrefix("popper.min.js")} type="text/javascript" />
        <script src={withPrefix("bootstrap.min.js")} type="text/javascript" />
        <script src={withPrefix("ekko-lightbox.js")} type="text/javascript" />
        <script
          src={withPrefix("ekko-lightbox.toggle.js")}
          type="text/javascript"
        />
      </Helmet>
    </div>
  )
}

export default Layout
