import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
// import footerStyles from "./footer.module.scss"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
          title
        }
      }
    }
  `)

  return (
    <footer className="py-3 bg-dark">
      <div className="container">
        <div className="row, text-center">
          <div className="col-md-12">
            <p className="mt-3 mb-0">
              Copyright &copy; {new Date().getFullYear()}{" "}
              <Link to="/">{data.site.siteMetadata.title}</Link> by{" "}
              <a
                href="https://pixeljuice.hu"
                target="_blank"
                rel="noreferrer"
                id="author"
              >
                {data.site.siteMetadata.author}
              </a>
            </p>
            <a
              href={`adatkezelesi-tajekoztato-lassucsardas.pdf`}
              target="_blank"
              rel="noreferrer"
              className="small"
            >
              Adatkezelési tájékoztató,{" "}
            </a>
            <a
              href="https://icons8.com/"
              target="_blank"
              rel="noreferrer"
              className="small"
            >
              Icons by Icons8
            </a>{" "}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
