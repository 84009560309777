import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import logo from "../images/Coda_40.png"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome } from "@fortawesome/free-solid-svg-icons"
// import headerStyles from "./header.module.scss"
// import Scrollspy from "react-scrollspy"

// import { AnchorLink } from "gatsby-plugin-anchor-links"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark fixed-top pr-5 py-0 " // py-0 hogy a link háttér végig menjen le és fel
      id="#navbar"
    >
      <Link to="/#logo" className="navbar-brand px-5">
        <img src={logo} alt={data.site.siteMetadata.title} />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div
        className="collapse navbar-collapse py-0"
        id="navbarSupportedContent"
      >
        <ul className="navbar-nav ml-auto text-uppercase">
          <li
            className="nav-item"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
          >
            <Link
              to="/"
              className="nav-link"
              id="home"
              activeClassName="active"
            >
              <FontAwesomeIcon icon={faHome} className="mx-4 fa-home" />
            </Link>
          </li>
          <li
            className="nav-item"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
          >
            <Link
              to="/#konyvek-index"
              className="nav-link"
              activeClassName="active"
              activeStyle={{ color: "red" }}
            >
              Könyvek
            </Link>
          </li>

          {/* <li
            className="nav-item"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
          >
            <Link
              to="/#irasok-index"
              className="nav-link"
              activeClassName="active"
            >
              Írások
            </Link>
          </li> */}

          <li
            className="nav-item"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
          >
            <Link
              to="/#chi_kung-index"
              className="nav-link"
              activeClassName="active"
              // activeStyle={{ backgroungColor: "red" }}
            >
              Chi Kung
            </Link>
          </li>

          {/* <li className="nav-item ">
            <Link to="/kepek" className="nav-link" activeClassName="active">
              Képek
            </Link>
          </li> */}

          <li className="nav-item ">
            <Link to="/rolam" className="nav-link" activeClassName="active">
              Rólam
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/kapcsolat" className="nav-link" activeClassName="active">
              Kapcsolat
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Header
